import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Email } from '../models/email';
import { User } from '../models/user';
import { UserHistory } from '../admin/history-diff-viewer/history-diff-viewer.component';

/**
 * Spezial Service für admin fragen die normalerweiße die fragewogen oder depot nicht brauchen.
 */
@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private userList: User[];
  private cancelButton = true;
  private institutionId$ = new BehaviorSubject<string | null>(null);
  constructor(private http: HttpClient) {}

  getUserList(userRole, lang) {
    if (this.userList && this.userList.length > 0) return of(this.userList);
    else
      return this.http
        .get<User[]>(`${environment.apiURL}/user/list`, {
          params: { userRole: userRole, lang: lang },
          withCredentials: true,
        })
        .pipe(shareReplay())
        .pipe(
          map((userDataList) => {
            return userDataList;
          })
        );
  }

  getUserHistoryById(id: string) {
    return this.http.get<UserHistory[]>(`${environment.apiURL}/user/history/${id}`, {
      withCredentials: true,
    });
  }

  getUserById(id: string) {
    return this.http
      .get<User>(`${environment.apiURL}/user/byid/${id}`, {
        withCredentials: true,
      })
      .pipe(
        map((resUser) => {
          return new User(resUser);
        })
      );
  }

  saveCustomInfo(userId: string, customInfo: string): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/user/${userId}/custom-info`,
      { customInfo },
      { withCredentials: true }
    );
  }

  importUser(data) {
    return this.http.post(`${environment.apiURL}/user/importuser/`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      responseType: 'text',
    });
  }

  sendAbweichungEmail(userId, customerMessage) {
    return this.http.post(`${environment.apiURL}/user/abweichungmelden`, {
      userId,
      customerMessage,
    });
  }

  getDocuments(fileId: string) {
    return this.http.get(`${environment.apiURL}/documents/getDocuments/${fileId}`, {
      withCredentials: true,
      responseType: 'blob',
    });
  }

  sendPepEmail(userId) {
    return this.http.post(`${environment.apiURL}/user/pepmeldung`, {
      userId,
    });
  }

  // saveDocument(userId: string, data: FormData) {
  //   return this.http
  //     .post(`${environment.apiURL}/postfach/documents/${userId}`, data, {
  //       withCredentials: true,
  //       responseType: "text",
  //     })
  //     .toPromise();
  // }

  // saveContacts(userId: string, data: FormData) {
  //   return this.http
  //     .post(`${environment.apiURL}/postfach/contacts/${userId}`, data, {
  //       withCredentials: true,
  //       responseType: "text",
  //     })
  //     .toPromise();
  // }

  // uploadUserContracts(userId: string, data: FormData) {
  //   return this.http.post(
  //     `${environment.apiURL}/postfach/user-documents-upload/${userId}`,
  //     data,
  //     { withCredentials: true, responseType: "text" }
  //   );
  // }

  // downloadUserContract(userId: string, date: Date) {
  //   return this.http.get(
  //     `${environment.apiURL}/postfach/user-documents-download/${userId}/${date}`,
  //     { withCredentials: true, responseType: "blob" }
  //   );
  // }

  // getSignUserPostfachDocumentOrder(userId: string, date: Date) {
  //   return this.http.get<any>(
  //     `${environment.apiURL}/postfach/sign-document/${userId}/${date}`,
  //     { withCredentials: true }
  //   );
  // }

  // deleteDocument(userId: string, postfachElementId: string) {
  //   return this.http.delete<any>(
  //     `${environment.apiURL}/postfach/document/${userId}/${postfachElementId}`,
  //     { withCredentials: true }
  //   );
  // }

  // deleteDocumentByDate(userId: string, postfachElementDate: Date) {
  //   return this.http.delete<any>(
  //     `${environment.apiURL}/postfach/document/${userId}/date/${postfachElementDate}`,
  //     { withCredentials: true }
  //   );
  // }

  deleteUserById(id: string) {
    return this.http.delete(`${environment.apiURL}/user/byid/${id}`, {
      withCredentials: true,
    });
  }

  // resetPassword(data: any) {
  //   return this.http.put<User>(
  //     `${environment.apiURL}/user/reset-password-email`,
  //     { email: data }
  //   );
  // }

  // lockUser(user: User) {
  //   return this.http.post(`${environment.apiURL}/user/lock/${user._id}`, null, {
  //     withCredentials: true,
  //   });
  // }

  // unlockUser(user: User) {
  //   return this.http.post(
  //     `${environment.apiURL}/user/unlock/${user._id}`,
  //     null,
  //     { withCredentials: true }
  //   );
  // }

  // setValidatedPesonalData(id: string, data: any) {
  //   return this.http.post(
  //     `${environment.apiURL}/user/validatedPesonalData/${id}`,
  //     data,
  //     { withCredentials: true }
  //   );
  // }

  // transferDocumentToBaader(id: string) {
  //   return this.http.post(
  //     `${environment.apiURL}/user/tranferBaaderDocuments/${id}`,
  //     null,
  //     { withCredentials: true }
  //   );
  // }

  resetUser(id: string) {
    return this.http.post(`${environment.apiURL}/user/reset-user/${id}`, null, {
      withCredentials: true,
    });
  }

  resetUserQuestions(id: string) {
    return this.http.post(`${environment.apiURL}/user/reset-questions/${id}`, null, { withCredentials: true });
  }

  // resetItm(user: User) {
  //   return this.http.post(
  //     `${environment.apiURL}/user/reset-itm/${user._id}`,
  //     null,
  //     { withCredentials: true }
  //   );
  // }

  changeRoleUser(userId: string, userRole, user) {
    return this.http.post(
      `${environment.apiURL}/user/change-rol/${userId}`,
      { rol: userRole, user: user },
      { withCredentials: true }
    );
  }

  getEmailsList() {
    return this.http.get<Email[]>(`${environment.apiURL}/email`, {
      withCredentials: true,
    });
  }

  setEmail(email: Email) {
    return this.http.post(`${environment.apiURL}/email`, email, {
      withCredentials: true,
    });
  }

  // setSignVertrag(user: User) {
  //   return this.http.post<any>(
  //     `${environment.apiURL}/itm/sign_vvVertrag/${user._id}`,
  //     null,
  //     { withCredentials: true }
  //   );
  // }

  getUserZipData(userId: string) {
    return this.http.get(`${environment.apiURL}/user/data-pack/${userId}`, {
      withCredentials: true,
      responseType: 'blob',
    });
  }

  setPriveUser(userId: string) {
    return this.http.post<any>(`${environment.apiURL}/user/prive-send/${userId}`, null, { withCredentials: true });
  }

  setMaintinance(data: { from: Date; to: Date; description: string; sendEmails: boolean; creator: User }) {
    return this.http.post(`${environment.apiURL}/maintinance`, data, {
      withCredentials: true,
    });
  }

  isServerInMaintinance(): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/maintinance`);
  }

  unblockUserById(userId: string) {
    return this.http.put(`${environment.apiURL}/user/unblock/${userId}`, {
      withCredentials: true,
    });
  }

  updateSubStatus(userId: string, substatus) {
    return this.http.put(`${environment.apiURL}/user/substatus/${userId}`, {
      substatus,
    });
  }

  getShowCancelButton(): boolean {
    return this.cancelButton;
  }

  setShowCancelButton(value: boolean) {
    this.cancelButton = value;
  }

  setInstitutionId(id: string): void {
    this.institutionId$.next(id);
  }

  getInstitutionId$() {
    return this.institutionId$.asObservable();
  }
}
