import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

/**
 * Service fuer alle daten von den server hollen/speichern
 */
@Injectable({
  providedIn: 'root',
})
export class DataService {
  berufsGruppe: any;
  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.berufsGruppe = this.auth.getBerufsGruppe();
  }

  setTranslation(currentLang: string, translations: any) {
    return this.http.put<any>(`${environment.apiURL}/lang/${currentLang}`, translations, { withCredentials: true });
  }

  setTranslationLogs(currentLang: string, translationsLogs: any) {
    return this.http.put<any>(`${environment.apiURL}/lang/translationLog/${currentLang}`, translationsLogs, {
      withCredentials: true,
    });
  }

  updateTranslationLog(translation: any) {
    return this.http.post<any>(`${environment.apiURL}/lang/translationLogs/updateEntry`, translation, {
      withCredentials: true,
    });
  }

  getDocument(documentNr: number) {
    let documentName = '';
    switch (documentNr) {
      case 1:
        documentName = 'Elektronisches Postfach';
        break;
      case 2:
        documentName = 'Girocard';
        break;
      case 3:
        documentName = 'Informationsbogen fuer den Einleger';
        break;
      case 4:
        documentName = 'Kundenstammvertrag fuer jur. Person';
        break;
      case 5:
        documentName = 'Onlinebanking';
        break;
      case 6:
        documentName = 'Kreditvertrag Kontokorrentkredite';
        break;
      case 7:
        documentName = 'Kreditkartenantrag Mastercard';
        break;
      case 8:
        if (this.berufsGruppe.sepaMandat === true) {
          documentName = 'SEPA Firmenlastschrift';
        }
        break;
      case 9:
        documentName = 'Selbstauskunft';
        break;
      case 10:
        documentName = 'GWG Vertragspartner';
        break;
      case 11:
        documentName = 'GWG Auftretender';
        break;
      case 12:
        documentName = 'Kundenstammvertrag GbR';
        break;
      case 13:
        documentName = 'Unterschriftsproben GbR';
        break;
      case 14:
        documentName = 'Kundenstamm Vertrag';
        break;
      case 15:
        documentName = 'Abtretungsvertrag';
        break;
      default:
        throw new Error('Invalid document number');
    }
    let user = this.auth.user;
    return this.http.post(
      `${environment.apiURL}/documents/vertraege/${documentName}`,
      { _id: user._id },
      {
        withCredentials: true,
        responseType: 'blob',
      }
    );
  }

  getRechtlichesDocument(documentNr: number) {
    let documentName = '';
    switch (documentNr) {
      case 1:
        documentName = 'Allgemeine_Geschaeftsbedingungen';
        break;
      case 2:
        documentName = 'Sonderbedingungen_fuer_das_Online-Banking';
        break;
      case 3:
        documentName = 'Sonderbedingungen_fuer_den_Lastschriftverkehr';
        break;
      case 4:
        documentName = 'Sonderbedingungen_fuer_den_Ueberweisungsverkehr';
        break;
      case 5:
        documentName = 'Sonderbedingungen_fuer_die_Ausfuehrung_von_Echtzeit-Ueberweisungen';
        break;
      case 6:
        documentName = 'Sonderbedingungen_fuer_die_Ausfuehrung_von_Echtzeit-Ueberweisungen_mittels_Sammelauftraegen';
        break;
      case 7:
        documentName = 'Sonderbedingungen_fuer_die_girocard_(Debitkarte)';
        break;
      case 8:
        documentName = 'Sonderbedingungen_fuer_die_Nutzung_des_elektronischen_Postfachs';
        break;
      case 9:
        documentName = 'Datenschutzhinweise';
        break;
      case 11:
        documentName = 'Informationsbogen fuer den Einleger';
        break;
      case 12:
        documentName = 'SCHUFA Klausel';
        break;
    }
    this.resetDocumetns();
    return this.http.get(
      `${environment.apiURL}/documents/rechtliches/${documentName}`,

      {
        withCredentials: true,
        responseType: 'blob',
      }
    );
  }

  saveAlldocumentsInDB() {
    this.resetDocumetns();
    return this.http.post(`${environment.apiURL}/documents/save-documents`, {
      _id: this.auth.user._id,
    });
  }

  archiveAlldocuments() {
    this.resetDocumetns();
    return this.http.post(`${environment.apiURL}/documents/archive-documents`, {
      _id: this.auth.user._id,
    });
  }

  uploadAusweiskopieDocument(documentData, natPersonId) {
    let user = this.auth.user;
    return this.http.post(`${environment.apiURL}/documents/uploadAusweiskopie`, {
      _id: user._id,
      natPersonId: natPersonId,
      documentData: documentData,
    });
  }

  getMyDocuments() {
    return this.http.get<{
      documents: {
        fileId: string;
        documentname: string;
        formularnummer: string;
        ablagenummer: number;
        documentdata: any;
        isIban: boolean;
      }[];
    }>(`${environment.apiURL}/documents/getMyDocuments/${this.auth.user._id}`);
  }

  uploadRechtsDocument(documentData) {
    let user = this.auth.user;
    return this.http.post(`${environment.apiURL}/documents/uploadRechtsDocument`, {
      _id: user._id,
      documentData: documentData,
    });
  }

  downloadAllEinsichtDocuments() {
    this.resetDocumetns();
    return this.http.post(
      `${environment.apiURL}/documents/download-documents-zip`,
      { _id: this.auth.user._id },
      {
        withCredentials: true,
        responseType: 'blob',
      }
    );
  }

  private resetDocumetns() {
    this.auth.user.documents = [
      {
        fileId: '',
        documentname: '',
        formularnummer: '',
        ablagenummer: 0,
        documentdata: undefined,
        isIban: false,
      },
    ];
  }
}
