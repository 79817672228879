import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdminService } from '../services/admin.service';

@Injectable({
  providedIn: 'root',
})
export class InstitutionIdFromQueryParamsGuard implements CanActivate {
  constructor(private adminService: AdminService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const institutionId = route.queryParamMap.get('institutionId');
    if (institutionId) {
      this.adminService.setInstitutionId(institutionId);
    } else {
      console.warn('No Institution ID found in query params.');
    }
    return true;
  }
}
